export default {
  name: 'wisk-save-system',
  width: 35,
  height: 35,
  paths: [
    {
      fill: '#201B4E',
      d: 'M17.515,35q-3.9,0-7.793,0c-2.2,0-4.394-.012-6.59,0A2.974,2.974,0,0,1,0,31.9q.007-8.14,0-16.28Q0,9.4,0,3.188A2.979,2.979,0,0,1,1.654.311,3.089,3.089,0,0,1,3.11.005q3.9,0,7.8,0H23.544q.693,0,1.385,0a1.25,1.25,0,0,1,.919.381l7.338,7.343c.383.383.748.788,1.157,1.14a1.989,1.989,0,0,1,.644,1.587c.019,2.768.009,5.536.01,8.3q0,3.91,0,7.822c0,1.926.016,3.852-.036,5.776a2.8,2.8,0,0,1-2.614,2.608c-1.523.051-3.048.033-4.573.035-2.608,0-5.215,0-7.823,0q-1.219,0-2.438,0ZM10.225,2.969a1.5,1.5,0,0,0-.187-.031q-3.431-.006-6.86-.011c-.2,0-.255.085-.252.27.007.492,0,.983,0,1.475L2.934,18.51q0,6.648,0,13.3v.274H7.291v-.566q0-4.738,0-9.478a2.914,2.914,0,0,1,1.726-2.8,3.716,3.716,0,0,1,1.7-.274q6.951,0,13.9,0a2.937,2.937,0,0,1,3.081,2.991c.015.732.005,1.465.005,2.2q0,3.836-.006,7.671c0,.227.075.289.3.287q1.9-.014,3.791,0c.247,0,.328-.069.325-.321-.014-1.063-.011-2.126-.01-3.189q0-8.679.01-17.359a.707.707,0,0,0-.231-.549q-3.431-3.42-6.854-6.847c-.06-.06-.127-.112-.225-.2-.008.125-.016.2-.016.278q0,3.791,0,7.581a3.039,3.039,0,0,1-.656,1.984,2.983,2.983,0,0,1-2.453,1.116q-1.5,0-3.009,0h-5.3a2.982,2.982,0,0,1-2.864-1.678,3.934,3.934,0,0,1-.293-1.881q0-3.309,0-6.619Zm.007,29.109h.309q6.963,0,13.927.01c.269,0,.336-.074.335-.337-.009-2.887-.006-5.775-.006-8.662V21.922H10.232ZM21.9,11.668v-.205c0-2.718,0-5.437.011-8.155,0-.31-.091-.386-.388-.385-2.478.011-4.955.006-7.433.006h-.935v8.739Z'
    },
    {
      fill: '#ea5234',
      transform: 'translate(-10, -20) scale(1.5)',
      d: 'M34.1,15.3c0.4-0.2,1.1-0.7,0.9-1.6c-0.2-0.5-0.4-1.1-0.7-1.5c-0.5-0.8-1.4-0.7-1.8-0.5 c0.2-0.4,0.3-1.3-0.5-1.7c-0.5-0.3-1-0.5-1.6-0.6c-0.9-0.2-1.5,0.4-1.6,0.8c-0.2-0.4-0.8-1.1-1.6-0.8c-0.6,0.1-1.1,0.4-1.6,0.6 c-0.8,0.4-0.7,1.3-0.5,1.7c-0.4-0.2-1.3-0.3-1.8,0.5c-0.3,0.5-0.5,1-0.7,1.5c-0.2,0.8,0.4,1.4,0.9,1.6c-0.4,0.2-1.1,0.7-0.9,1.6 c0.1,0.4,0.2,0.7,0.4,1c0.2,0.5,0.7,0.7,1.1,0.7c0.4,0,0.7-0.1,1-0.2c-0.2,0.2-0.4,0.6-0.4,0.9c0,0.4,0.1,0.8,0.6,1.1 c0.6,0.4,1.2,0.7,1.9,0.8c0.9,0.2,1.5-0.4,1.6-0.8c0.2,0.4,0.8,1,1.6,0.8c0.7-0.2,1.4-0.5,1.9-0.8c0.5-0.3,0.6-0.8,0.6-1.1 c0-0.3-0.2-0.7-0.4-0.9c0.3,0.2,0.7,0.3,1,0.2c0.4,0,0.8-0.2,1.1-0.7c0.2-0.3,0.3-0.7,0.4-1C35.2,16,34.5,15.5,34.1,15.3z  M33.7,16.3c0.1,0.1,0.3,0.2,0.2,0.3c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.2-0.3,0.2-0.5,0.1c-0.2-0.1-0.4-0.2-0.6-0.2 c-0.6,0-1.1,0.5-1.1,1c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.2,0.4,0,0.5c-0.5,0.3-1,0.6-1.6,0.7c-0.2,0-0.3-0.1-0.4-0.2 c-0.2-0.4-0.6-0.7-1-0.7c-0.4,0-0.8,0.3-1,0.7c-0.1,0.1-0.2,0.3-0.4,0.2c-0.6-0.1-1.1-0.4-1.6-0.7c-0.2-0.1-0.1-0.4,0-0.5 c0.2-0.2,0.3-0.4,0.3-0.7c0-0.6-0.5-1-1.1-1c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.1-0.5-0.1c-0.1-0.3-0.2-0.6-0.3-0.9 c0-0.1,0.1-0.3,0.2-0.3c0.4-0.2,0.7-0.5,0.7-1c0-0.4-0.3-0.8-0.7-0.9c-0.1-0.1-0.3-0.2-0.2-0.3c0.1-0.4,0.3-0.9,0.6-1.3 c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.6,0,1.1-0.5,1.1-1c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.3,0.1-0.4 c0.4-0.2,0.9-0.4,1.3-0.5c0.2,0,0.3,0.1,0.4,0.2c0.2,0.4,0.6,0.6,1,0.6c0.5,0,0.9-0.3,1-0.6c0.1-0.1,0.2-0.3,0.4-0.2 c0.5,0.1,0.9,0.3,1.3,0.5c0.1,0.1,0.2,0.3,0.1,0.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.6,0.5,1,1.1,1c0.2,0,0.3,0,0.5-0.1 c0.2-0.1,0.3,0,0.4,0.1c0.3,0.4,0.4,0.8,0.6,1.3c0,0.1-0.1,0.3-0.2,0.3c-0.4,0.1-0.7,0.5-0.7,0.9C33,15.7,33.3,16.1,33.7,16.3z'
    },
    {
      fill: '#ea5234',
      transform: 'translate(-10, -20) scale(1.5)',
      d: 'M28.7,12.2c-1.8,0-3.2,1.4-3.2,3.1s1.5,3.1,3.2,3.1c1.8,0,3.2-1.4,3.2-3.1S30.5,12.2,28.7,12.2z M28.7,17.4 c-1.2,0-2.1-0.9-2.1-2.1c0-1.1,1-2,2.1-2c1.2,0,2.2,0.9,2.2,2C30.8,16.4,29.9,17.4,28.7,17.4z'
    }
  ]
}

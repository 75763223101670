<template>
  <wiskInputGroup fluid class="mx-auto" style="max-width: 800px;" :legend="translations.txtVenuesStats">
    <b-row v-for="item in venueStats" :key="item.key" class="mb-1" :class="{ 'mt-3': item.categoryTitle }">
      <b-col>
        <span :class="{ 'text-bold': item.categoryTitle }"> {{ item.title }} </span>
      </b-col>
      <b-col v-if="!item.categoryTitle">
        <span class=""> {{ item.value }} </span>
      </b-col>
    </b-row>
  </wiskInputGroup>
</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import { currencyFormat, formatDate } from '@/modules/utils'

export default {
  name: 'VenueStats',
  props: { venue: Object, stats: Object },
  computed: {
    ...mapState(['translations']),
    venueStats() {
      if (this.stats) {
        let stats = { ...this.stats }
        stats.health = stats.health || {}
        stats.health.pos_items = stats.health.pos_items || {}
        stats.health.prices = stats.health.prices || {}
        stats.health.distributors = stats.health.distributors || {}
        stats.health.par_levels = stats.health.par_levels || {}
        stats.sales = stats.sales || {}
        stats.over_stock = stats.over_stock || {}
        stats.intakes = stats.intakes || {}
        stats.inventories = stats.inventories || {}
        stats.inventories.first = stats.inventories.first || {}
        stats.inventories.first.interval = stats.inventories.first.interval || {}
        stats.inventories.last = stats.inventories.last || {}
        stats.inventories.last.stats = stats.inventories.last.stats || {}
        return [
          //Info
          { key: 10, title: this.translations.txtGenericInfo, categoryTitle: true },
          { key: 11, title: this.translations.txtVenueOperationsCentralVenue, value: this.venue.parent_venue_id || '-' },
          {
            key: 12,
            title: this.translations.txtVenueStatsCentralItemsList,
            value: this.venue.use_parent_items ? this.translations.txtGenericYes : this.translations.txtGenericNo
          },
          {
            key: 13,
            title: this.translations.txtVenueStatsCentralPOSItemsList,
            value: this.venue.use_parent_pos_items ? this.translations.txtGenericYes : this.translations.txtGenericNo
          },

          //POS
          { key: 200, title: this.translations.txtVenuesPOS, categoryTitle: true },
          { key: 203, value: (this.venue.last_sales_check && DateTime.fromISO(this.venue.last_sales_check).toRelative()) || '-', title: this.translations.txtVenuesLastSaleCheck },
          {
            key: 204,
            value: (stats.sales.last_sale_import_date && DateTime.fromISO(stats.sales.last_sale_import_date).toRelative()) || '-',
            title: this.translations.txtVenuesDaysSinceLastSaleImported
          },

          //Inventory
          { key: 300, title: this.translations.txtMenuInventory, categoryTitle: true },
          { key: 301, value: stats.inventories.unique_items, title: this.translations.txtVenuesVenueBottlesCount },
          { key: 302, value: stats.inventories.count, title: this.translations.txtVenuesInventoriesCount },
          { key: 303, value: formatDate(stats.inventories.first.interval.start), title: this.translations.txtVenuesFirstInventoryDate },
          { key: 305, value: currencyFormat(stats.inventories.last.stats.dollars), title: this.translations.txtVenuesLastInventoryValue },
          {
            key: 306,
            value: (stats.inventories.last_inventory_date && DateTime.fromISO(stats.inventories.last_inventory_date).toRelative()) || '-',
            title: this.translations.txtVenuesDaysSinceLastInventory
          },

          //Intake
          { key: 400, title: this.translations.groupMovementTypes.intake, categoryTitle: true },
          {
            key: 401,
            value: (stats.intakes.last_intake_date && DateTime.fromISO(stats.intakes.last_intake_date).toRelative()) || '',
            title: this.translations.txtVenuesDaysSinceLastIntake
          },
          { key: 402, value: stats.intakes.count, title: this.translations.txtVenuesIntakesCount },
          { key: 403, value: currencyFormat(stats.intakes.weekly_average_value), title: this.translations.txtVenuesIntakesWeeklyAverage },
        ]
      }

      return []
    }
  }
}
</script>

<template>
  <wiskModal :visible="!!editAction" :hideFooter="!!selectedEditorTab || !!(venueComputed && venueComputed.id)" extraLarge
    @hide="setGlobalAction({ type: 'venueEdit', action: null })" size="lg">

    <template v-slot:modal-header>
      <b-container fluid>
        <b-row noGutters>
          <b-col cols="12" md="4" class="mb-2-m">
            <h5 class="modal-title me-2 float-start w-100" style=""> {{ modalTitle }}
              <small class="text-muted" v-if="venueComputed && venueComputed.id"> - ({{ venueComputed.id }}) </small>
            </h5>
          </b-col>
          <b-col cols="12" md="8" class="px-0-m">
            <b-form-radio-group style="margin-left: 15px;" buttons v-model="selectedEditorTab" button-variant="outline-primary"
              v-if="user && user.user_venues.length && venueComputed && venueComputed.id" size="sm">
              <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
              <b-form-radio :value="1"> {{ translations.columnDetails }} </b-form-radio>
              <b-form-radio :value="2"> {{ translations.txtGenericUsers }} </b-form-radio>
              <b-form-radio v-if="venueComputed.id === venue.id" :value="3"> {{ translations.txtVenuesRoles }} </b-form-radio>
              <b-form-radio :value="4"> {{ translations.txtGenericTimeline }} </b-form-radio>
              <b-form-radio v-if="venueComputed.id === venue.id" :value="5"> {{ translations.txtGLAccounts }} </b-form-radio>
              <b-form-radio v-if="venueComputed.taxes_enabled && venueComputed.id === venue.id" :value="6"> {{ translations.txtTaxes }} </b-form-radio>
              <b-form-radio :value="7"> {{ translations.txtVenuesPartnerVenues }} </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>

        <button @click="setGlobalAction({ type: 'venueEdit', action: null })" aria-label="Close" class="close btn-close position-absolute">
          <icon class="text-secondary" name="wisk-exit" :scale="0.64" />
        </button>
      </b-container>
    </template>

    <h5 v-if="user && !user.user_venues.length" class="mb-5">
      {{ translations.txtUserOnboardingVenueNone }}
    </h5>

    <div class="" style="height: calc(100vh - 250px);">
      <div v-if="selectedEditorTab === 0">
        <venueOperations @input="operations = $event" v-if="venueComputed" :venue="venueComputed" :currentVenue="venue" @validStateChanged="$event => valid = $event" :key="key"
          @singleOperation="save" @refresh="getVenue" :preloadTitle="preloadTitle" />
      </div>
      <div v-if="selectedEditorTab === 1">
        <venueStats v-if="stats" :stats="stats" :venue="venueComputed" />
      </div>
      <div v-if="selectedEditorTab === 2">
        <venuesUsers :gridStyle="{ height: '100%' }" :usersVenuesData="usersVenuesDataForCurrentVenue"
          @refresh="getUsersVenuesData" />
      </div>
      <div v-if="selectedEditorTab === 3">
        <rolesPermissions />
      </div>
      <div v-if="selectedEditorTab === 4">
        <timeline v-if="venueComputed" :filters="[{ type: 'venue', id: venueComputed.id }]"
          parentGridName="VenueEditTimeline" :gridStyle="{ height: '100%' }" />
      </div>
      <div v-if="selectedEditorTab === 5">
        <glAccounts v-if="venueComputed" height="100%"/>
      </div>
      <div v-if="selectedEditorTab === 6">
        <taxes v-if="venueComputed" height="100%" />
      </div>
      <div v-if="selectedEditorTab === 7">
        <partnerVenues v-if="venueComputed" :venueData="usersVenuesDataForCurrentUser" @query="getUsersVenuesDataForPartnerVenues" />
      </div>
    </div>

    <confirm ref="confirmDialog" />

    <confirm ref="confirmDialogClone" />

    <loading :loading="loading" />

    <template v-slot:modal-footer v-if="!selectedEditorTab">

      <editorButtons v-if="venueComputed" :editorValid="valid" :previous="editAction && editAction.previous" :next="editAction && editAction.next"
        :save="save" :loading="loading" />
    </template>
  </wiskModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import get from 'lodash.get'
import api from '@/api'
import venueOperations from '@/components/venues/VenueOperations'
import venueStats from '@/components/venues/VenueStats'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import venuesUsers from '@/components/users/VenuesUsers'
import rolesPermissions from '@/components/users/RolesPermissions'
import partnerVenues from '@/components/venues/PartnerVenues'
import timeline from '@/components/timeline/Timeline'
import loading from '@/components/common/WiskLoading'
import glAccounts from '@/components/accounting/GLAccounts'
import taxes from '@/components/accounting/Taxes'

export default {
  name: 'VenueEdit',
  components: { partnerVenues, venueOperations, editorButtons, venueStats, venuesUsers, timeline, loading, rolesPermissions, glAccounts, taxes },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      operations: [],
      valid: true,
      selectedEditorTab: 0,
      preloadTitle: '',
      usersVenuesDataForCurrentVenue: [],
      usersVenuesDataForCurrentUser: [],
      key: 1,
      stats: null,
      loading: false,
      localVenue: null,
      copyToVenueParams: {
        from_venue_id: 0,
        title: '',
        anonymize_data: false,
        with_inventories: false,
        assign_users: false,
        with_pos_items: false,
        with_sales: false,
        with_custom_fields: false
      }
    }
  },
  computed: {
    ...mapState(['user', 'translations', 'rolesByVenueId', 'currentPermissionsByType', 'venueStats', 'venue']),
    itemsCopyOptions() {
      return [
        { title: this.translations.txtVenueOperationsCopyItemsNone, id: 'none' },
        { title: this.translations.txtVenueOperationsCopyItems, id: 'items' },
        { title: this.translations.txtVenueOperationsCopyPOSItems, id: 'items_and_pos_mappings' }
      ]
    },
    modalTitle() {
      if (this.venueComputed) {
        return this.venueComputed.title || this.translations.txtVenuesNew
      }
      return ''
    },
    venueComputed() {
      if (this.localVenue) {
        return this.localVenue
      }
      if (this.editAction && this.editAction.venue) {
        return this.editAction.venue
      }
      if (this.editAction && this.editAction.id === 0) {
        return {
          pos: {},
          payment: {},
          subscription: {},
          country: '',
          id: 0,
          state: '',
          title: (this.user && this.user.signup_parameters && this.user.signup_parameters.venue) || ''
        }
      }

      return null
    }
  },
  methods: {
    ...mapActions(['updateVenue', 'setGlobalAction', 'loadVenuesHighLevel', 'changeActiveVenue']),
    openPlanSelector() {
      this.setGlobalAction({ type: 'planSelectorEdit', action: { venue: this.venueComputed } })
    },
    save(operations = this.operations) {
      if (this.venueComputed && (this.valid || operations)) {
        this.loading = true

        const operationsPrepared = Array.isArray(operations) ? operations : [operations],
          copyOperationIndex = operationsPrepared.findIndex(o => o.type === 'copy_data_from_venue')

        if (copyOperationIndex > -1) {
          operationsPrepared[copyOperationIndex] = {
            type: 'copy_data_from_venue',
            value: {
              venue_id: this.venue.id,
              data_filter: {
                type: operationsPrepared[copyOperationIndex].value
              }
            }
          }
        }
        this.updateVenue({ id: this.venueComputed.id || 0, operation: operationsPrepared })
          .then(result => {
            this.operations = []
            this.localVenue = { ...result }
            this.key++

            if (typeof this.editAction.onChange === 'function') {
              this.editAction.onChange(result)
            }

            if (this.editAction.id === 0 && result && result.id) {
              let currentVenue = get(this, '$store.getters.venue')

              if (currentVenue?.id && currentVenue.id !== result.id) {
                setTimeout(() => {
                  this.changeActiveVenue({ venueId: result.id, userId: this.user.id })
                }, 2000)
              } else {
                this.loadVenuesHighLevel()

                if (this.user && !this.user.venue) {
                  window.location.reload()
                }
              }

              this.setGlobalAction({ type: 'venueEdit', action: null })
            }
            this.loading = false
          })
          .catch(() => {
            this.operations = []
            this.key++
            this.loading = false
          })
      }
    },
    getVenue() {
      if (this.editAction && this.editAction.id) {
        if (this.venue && this.venue.id === this.editAction.id) {
          this.localVenue = { ...this.venue }
          this.stats = this.venueStats
        } else {
          this.loading = true
          api
            .venueById(this.editAction.id)
            .then(venue => {
              this.localVenue = { ...venue }
            })
            .catch(() => {
              this.setGlobalAction({ type: 'venueEdit', action: null })
            })
            .finally(() => {
              this.loading = false
            })

          api.venueStats(this.editAction.id).then(stats => {
            this.stats = stats
          })
        }
      }
    },
    getUsersVenuesDataForPartnerVenues(query = '') {
      if (this.selectedEditorTab === 7 && this.venueComputed?.id && ((this.user?.god_mode && query.length > 2) || !this.user?.god_mode)) {
        api.usersVenues({ query }).then(data => {
          let mapped = data
            .filter(d => (d && d.user && d.user.id === this.user.id) || this.user.god_mode)
            .map(item => ({ ...item, venue_title: item.venue.title, user_full_name: item.user.name, user_email: item.user.email }))
          mapped.forEach(item => {
            item.venue.roles = this.rolesByVenueId[item.venue.id] || []
          })
          this.usersVenuesDataForCurrentUser = mapped
        })
      }
    },
    getUsersVenuesData() {
      if (this.selectedEditorTab === 2 && this.venueComputed?.id) {
        api.usersVenues({ venueId: this.venueComputed.id }).then(data => {
          let mapped = []

          data.forEach(item => {
            item = { ...item, venue_title: item.venue.title, user_full_name: item.user.name, user_email: item.user.email }
            item.venue.roles = (this.rolesByVenueId[item.venue.id] || []).filter(r => r && !r.archived)

            if (!item.user.is_wisk_user || this.user.is_wisk_user || this.user.god_mode) {
              mapped.push(item)
            }
          })

          this.usersVenuesDataForCurrentVenue = mapped
        })
      }

      this.getUsersVenuesDataForPartnerVenues()
    }
  },
  mounted() {
    this.selectedEditorTab = this.editAction.selectedEditorTab || 0
    this.preloadTitle = this.editAction.preloadTitle

    this.getVenue()
  },
  watch: {
    venue(venue) {
      if (venue.id === this.editAction.id) {
        this.getVenue()
        this.getUsersVenuesData()
      }
    },
    operations: {
      handler(operations) {
        if (operations && operations.length && this.venueComputed && this.venueComputed.id) {
          this.save(operations)
        }
      }
    },
    selectedEditorTab: {
      immediate: true,
      handler: 'getUsersVenuesData'
    }
  }
}
</script>

<style></style>

import merge from 'lodash.merge'
import uniq from 'lodash.uniq'
import get from 'lodash.get'
import { arrayToObjectById, prepareItemsForWiskInput, isValidURL, prepareVariantAsItem } from '@/modules/utils'

export default {
  languages: state => ((state.options && state.options.languages) || []).map(l => ({ ...l, id: l.short })),
  timeZones: state => ((state.options && state.options.timezones) || []).map(t => ({ ...t, name: t.timezone, namedOffset: t.zonename })),
  customFieldsTypeTranslations: state => ({
    url: { inputType: 'text', urlInput: true, validations: [{ type: 'url', validator: isValidURL, message: state.translations.txtValidationURL }] },
    small_text: { inputType: 'text' },
    attachments: { inputType: 'attachments' },
    large_text: { inputType: 'text', multiline: true },
    checkmark: { inputType: 'checkbox' },
    dropdown: { inputType: 'wiskSelect' },
    multiselect: { inputType: 'wiskSelect', multiselectOptions: {}, multiple: true, trackBy: 'uuid' },
    round_number: { inputType: 'number', decimals: 0 },
    decimal_number: { inputType: 'number' },
    currency: { inputType: 'number', decimals: 2 },
    date: { inputType: 'datepicker' }
  }),
  printBarcodeConfigs: state =>
    (state.options && state.options.print_barcode_configs) || [
      {
        /* later here will be also an option saying barcode or qr code */
        id: 'dymo_450',
        type: 'dymo',
        title: 'DYMO LabelWriter 450',
        labels: [
          { id: '19x51', title: '19mm x 51mm (¾” x 2”)' },
          { id: '25x54', title: '25mm x 54mm (1” x 2 ⅛”)' },
          { id: '28x89', title: '28mm x 89mm (1 ⅛” x 3 ½”)' },
          { id: '36x89', title: '36mm x 89mm (1 4/10” x 3 ½”)' },
          { id: '51x89', title: '51mm x 89mm (2” x 3 ½”)' },
          { id: '54x70', title: '54mm x 70mm (2 ⅛” x 2 ¾”)' },
          { id: '54x101', title: '54mm x 101mm (2 ⅛” x 4”)' }
        ],
        software_list: [
          { id: 'label', title: 'Dymo Label > 8.7.5 (works on Mac and Windows)' },
          { id: 'connect', title: 'Dymo Connect > 1.4.2 (works on Windows)' }
        ],
        label: '36x89'
      }
    ],
  customFieldTypes: state => (state.options && state.options.custom_field_types.filter(c => c && c.id !== 'images')) || [],
  allergenSearchList: state => (state.options && state.options.default_allergens) || [],
  activeAllergens: state => state.allergens.filter(a => a && !a.archived),
  userNotificationTypes: state => (state.options && state.options.user_notification_types) || [],
  timelineFilterTypes: state => (state.options && state.options.timeline_filter_types) || [],
  permissionsByVenueIdByType: state =>
    arrayToObjectById(
      get(state, 'user.user_venues', []).map(
        userVenue => ({
          id: userVenue.venue.id,
          ...arrayToObjectById(
            get(userVenue, 'role.permissions', []).map(id => state.permissionsById[id]),
            'key'
          )
        }),
        'id'
      )
    ),
  venuesHighlevelById: state =>
    arrayToObjectById(
      get(state, 'user.user_venues', []).map(uv => uv.venue),
      'id'
    ),
  usersHighlevelById: state => arrayToObjectById(get(state, 'venue.users', []), 'id'),
  currencies: state => get(state, 'options.currencies', []).map(c => ({ ...c, title: `${c.name} (${c.code}${(c.symbol && ' - ' + c.symbol) || ''})` })),
  currency: (state, getters) => {
    let found = getters.currencies.find(c => c.code === getters.venue.display_currency.code)
    return (found && found.symbol) || getters.venue.display_currency.code
  },
  deviceType: state => state?.deviceInfo?.device?.type,
  activeItems: (state, getters) => (getters.venue.parent_venue_id ? state.activeItemIds.map(id => state.bottlesById[id] || { item_id: id }) : state.bottles),
  partnerVenues: state => state.user?.venue_group?.venues,
  venue: state => ({ subscription: {}, display_currency: {}, ...(state.venue || {}) }),
  defaultUMVolume: state => state?.venue?.default_um_volume || 'ml',
  defaultUMWeight: state => state?.venue?.default_um_weight || 'g',
  activeLocations: state => state.locations.filter(l => !l.archived),
  activeGLAccounts: state => state.glAccounts.filter(l => !l.archived),
  activeTaxes: state => state.taxes.filter(t => !t.archived),
  countries: state => get(state, 'options.countries', []),
  categoryPartialMeasureTypes: () => prepareItemsForWiskInput(['visual', 'scale', 'none']),
  venueSubscriptionStatuses: () => prepareItemsForWiskInput(['not_yet_required', 'select_plan', 'add_payment', 'delinquent', 'churned', 'active'], true),
  daysOfWeek: state => ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(d => ({ id: d, title: get(state, `translations.${d}`) })),
  billingTypes: () => prepareItemsForWiskInput(['monthly', 'yearly']),
  orderFormats: () => [
    { id: 'unit', title: 'unit' },
    { id: 'case', title: 'case' }
  ],
  posModifierMappingTypes: state => [
    { id: 'not_mapped', title: state.translations?.groupPOSModifierTypes?.not_mapped },
    { id: 'label', title: state.translations?.groupPOSModifierTypes?.label },
    {
      id: 'ingredient_change',
      title: state.translations?.groupPOSModifierTypes?.ingredient_change,
      subTypes: [
        { id: 'add', title: state.translations?.groupPOSModifierTypes?.add },
        { id: 'subtract', title: state.translations?.groupPOSModifierTypes?.subtract },
        { id: 'remove', title: state.translations?.groupPOSModifierTypes?.remove }
      ]
    },
    {
      id: 'measurement_change',
      title: state.translations?.groupPOSModifierTypes?.measurement_change,
      subTypes: [
        { id: 'multiplier', title: state.translations?.groupPOSModifierTypes?.multiplier },
        { id: 'base_change', title: state.translations?.groupPOSModifierTypes?.base_change }
      ]
    }
  ],
  measurements: state => state.options.measurements.map(m => ({ ...m, units_of_measurements: m.units_of_measurements.map(u => ({ ...u, type: m.id })) })),
  measurementsByType: state => arrayToObjectById(state.options.measurements, 'id'),
  activeDistributors: state => state.distributors.filter(d => !d.archived),
  tutorials: state => (state.options.intercom_product_tours || []).map(tour => ({ ...tour, path: tour.url.replace('https://web.wisk.ai', '') })),
  venueTypes: state => (state.user && state.user.god_mode ? prepareItemsForWiskInput(state.options.venue_types) : prepareItemsForWiskInput(['beverage', 'food'])),
  onboardingTypes: state => prepareItemsForWiskInput(state.options.onboarding_types),
  partialMeasuringTypes: state => prepareItemsForWiskInput(state.options.partial_measuring_types),
  taxSchemes: state => prepareItemsForWiskInput(state.options.taxes_schemes),
  statuses: state => prepareItemsForWiskInput(state.options.statuses),
  posTypes: state => state.options.pos_types,
  salesReps: state => state.options.sales_reps,
  posIntegrationStatuses: state => prepareItemsForWiskInput(state.options.pos_integration_statuses),
  shoppingCartCount: state => state.shoppingCart.length,
  countriesByCode: state => {
    let countries = get(state, 'options.countries', []),
      arr = []
    for (let i = 0; i < countries.length; i++) {
      let country = merge({}, countries[i])
      if (!Array.isArray(country.states)) {
        country.states = []
      }
      country.statesByCode = arrayToObjectById(country.states, 'code')
      arr.push(country)
    }

    return arrayToObjectById(arr, 'code')
  },
  itemVariantsAsItems: state => state.itemVariants.map(v => prepareVariantAsItem(v, state)),
  activeFamilies: state => state.families.filter(d => !d.archived).map(f => ({ ...f, categories: state.categories.filter(c => !c.archived && c.family_id === f.id) || [] })),
  categoriesById: state => state.categoriesById,
  categoriesByFamilyId: state => {
    let map = {}

    state.families.forEach(family => {
      map[family.id] = state.categories.filter(c => c.family_id === family.id)
    })

    return map
  },
  duplicatedBottlesBarcodes: state => {
    let allBarCodes = []
    let bottlesCodesArray = state.bottles.filter(bottle => !bottle.archived).map(bottle => uniq(bottle.barcodes))
    bottlesCodesArray.forEach(codeArray => {
      codeArray.forEach(code => {
        allBarCodes.push(code)
      })
    })
    const duplicatedCodes = allBarCodes.filter((v, i, a) => a.indexOf(v) !== i)
    return duplicatedCodes
  },
  configDataLoaded: state => !!(state.options?.loaded && state.user?.id && state.venue?.id),
  upgradeNowTextComputed: state => (get(state, 'venue.subscription.selected_plan') ? get(state, 'translations.txtVenueOperationsUpgradeNow') : get(state, 'translations.txtVenueOperationsSelectPlan')),
  siblingVenues: state => {
    if (state.user.god_mode) {
      return state.venuesHighLevel.filter(v => v.id !== state.venue.id && v.organization_id === state.venue.organization_id)
    }
    const userVenues = state.user.user_venues.map(uv => uv.venue),
      mergedVenues = merge(arrayToObjectById(userVenues), state.venuesHighLevelById)
    return Object.values(mergedVenues).filter(uv => uv.id !== state.venue.id)
  },
  posIntegrationSettings: state => ({
    is_menu_category_supported: state.posIntegrations.some(i => i.is_menu_category_supported),
    is_sale_category_supported: state.posIntegrations.some(i => i.is_sale_category_supported),
    support_revenue_centers: state.posIntegrations.some(i => i.support_revenue_centers)
  })
}

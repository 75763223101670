<template>
  <div v-if="loadingScreenVisible || forceVisible" class="loading-screen">
    <div class="inner" style="width: 380px; margin-left: auto; margin-right: auto; margin-top: 100px;">

      <img v-if="!initialDBLoadingProgress?.total" src="/img/WISK-logo-new.svg" alt="WISK Logo" class="wisk-logo hide-dark-mode mb-3" />
      <img v-if="!initialDBLoadingProgress?.total" src="/img/WISK-logo-new-white.svg" alt="WISK Logo" class="wisk-logo hide-light-mode mb-3" />

      <img v-if="initialDBLoadingProgress?.total" src="/img/WISK-logo-new-animated.svg" alt="WISK Logo" class="wisk-logo hide-dark-mode mb-3" />
      <img v-if="initialDBLoadingProgress?.total" src="/img/WISK-logo-new-animated-white.svg" alt="WISK Logo" class="wisk-logo hide-light-mode mb-3" />

      <!-- <img src="/img/WISK-logo-new-animated.svg" alt="WISK Logo" class="wisk-logo mb-3" /> -->

      <div v-if="initialDBLoadingProgress?.total" class="my-5">
        <h3>
          {{ `${progress.toFixed(0)}%` }}
        </h3>

        <div class="progress my-3" style="height: 4px; border-radius: 0; background-color: var(--blue-300);">
          <div class="progress-bar" role="progressbar" :style="{ 'width': progress.toFixed(0) + '%' }" style="border-radius: 0; background-color: var(--blue-400);"
            :aria-valuenow="initialDBLoadingProgress.done || 0" aria-valuemin="0" :aria-valuemax="initialDBLoadingProgress.total">
          </div>
        </div>
        <h4 class="position-relative text-dark mt-4" :style="initialDBLoadingProgress.style">
          {{ initialDBLoadingProgress.message }}
        </h4>
      </div>
      <div v-if="initialDBLoadingProgress && !initialDBLoadingProgress.total" class="mb-5">
        <h3 class="position-relative text-dark mt-4">
          {{ initialDBLoadingProgress.message }}
        </h3>
      </div>
      <!-- <div class="loading-issue-help" v-if="pastWaitTime">
        <h1>Experiencing loading issues?</h1>
        <h3 class="mb-3">Try clearing your site data on Google Chrome</h3>
        <ul>
          <h5 v-for="step in chromeSteps" :key="step.title" class="mb-3">
            <li>
              {{ step.title }}
              <img :src="step.image" class="w-100 mt-1" v-if="step.image" />
            </li>
          </h5>
        </ul>
      </div> -->
    </div>
    <div class="font-monospace" :class="[!pastWaitTime && 'loading-footer', pastWaitTime && 'my-5 p-5 text-right']">
      {{ wiskWebAppVersion }} &nbsp; | &nbsp; {{ wiskWebAppCOMMITHASH }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoadingScreen',
  props: { forceVisible: Boolean },
  data() {
    return {
      wiskWebAppVersion: window.wiskWebAppVersion,
      wiskWebAppCOMMITHASH: window.wiskWebAppCOMMITHASH,
      pastWaitTime: false,
      timer: null,
      chromeSteps: [
        {
          title: 'On web.wisk.ai, click the lock icon next to the address bar.',
          image: '/img/loading-page/ClearData1a.png'
        },
        {
          title: 'Click "Site settings"',
          image: '/img/loading-page/ClearData2a.png'
        },
        {
          title: 'Click "Clear data"',
          image: '/img/loading-page/ClearData3a.png'
        },
        {
          title: 'Click "Clear" on the confirmation window. This will sign you out of the WISK Web Portal.',
          image: '/img/loading-page/ClearData4a.png'
        },
        {
          title: 'Log back in and try to load the page again'
        }
      ]
    }
  },
  computed: {
    ...mapState(['loadingScreenVisible', 'initialDBLoadingProgress']),
    progress() {
      if (this.initialDBLoadingProgress && this.initialDBLoadingProgress.total) {
        let done = this.initialDBLoadingProgress.done || 0,
          progress = Math.floor(done / this.initialDBLoadingProgress.total * 100)
        return progress > 100 ? 100 : progress
      }

      return 0
    }
  },
  // mounted() {
  //   if (navigator.userAgent.includes('Chrome') && !navigator.userAgent.includes('Edg')) {
  //     this.timer = setTimeout(() => {
  //       this.pastWaitTime = true
  //     }, window.location.host === 'web.wisk.ai' ? 3 * 60 * 1000 : 10000)
  //   }
  // },
  // beforeUnmount() {
  //   if (this.timer) clearTimeout(this.timer)
  // }
}
</script>

<style lang="scss">
.loading-screen {
  text-align: center;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  background-color: #e4e5e6;
  overflow-y: auto;
}

.wisk-logo {
  animation: pulsate 2s infinite;
  animation-delay: 4s;
}

@keyframes pulsate {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
}

.loading-issue-help {
  text-align: left;

  ul {
    list-style-type: decimal;
    list-style-position: inside;
  }
}

.loading-footer {
  position: absolute;
  bottom: 100px;
  right: 100px;
}

.text-right {
  text-align: right;
}

@keyframes wisk-loading {
  0% {
    animation-timing-function: cubic-bezier(0.9647, 0.2413, -0.0705, 0.7911);
    transform: scale(0.9099999999999999);
  }

  51% {
    animation-timing-function: cubic-bezier(0.9226, 0.2631, -0.0308, 0.7628);
    transform: scale(1.02994);
  }

  100% {
    transform: scale(0.9099999999999999);
  }
}
</style>

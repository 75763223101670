<template>
  <v-dropdown :triggers="['click']" :distance="5" @show="scrollToBottom" class="text-center" :container="`#${containerDomId}`" ref="popper">
    <b-button :class="{ 'needs-review': lineItem.needs_review, 'needs-response': lineItem.needs_response, 'has-messages': lineItem.review_conversation?.length }"
      class="bt-needs-attention p-0 m-0 position-relative" size="x-sm" variant="link" v-tooltip.html="{ content: mainButtonTooltip, html: true }">
      <icon :scale="0.8" name="wisk-chat" class="me-1 align-self-md-center" />
      <span v-if="!iconOnly">
        {{ translations.txtScannedInvoicesMissingInformation }}
      </span>

      <b-badge v-if="lineItem?.review_conversation?.length" :variant="lineItem.needs_review || lineItem.needs_response ? 'danger' : 'success'" size="sm" class=""
        style="position: absolute; top: -5px; right: -10px; padding: 4px 4px; line-height: 5px;">
        {{ lineItem.review_conversation.length }}
      </b-badge>
    </b-button>

    <template #popper>
      <b-container fluid class="p-2" style="overflow: hidden;">
        <!-- header will be here later -->
        <b-row class="mb-3" v-if="!disabled && !messagesGrouped.length">
          <b-col cols="12">
            <b>{{ translations.txtDraftInvoiceChatConversationStart }}</b>
            <br />
            <small class="text-muted" style="margin-top: -3px; display: block;"> {{ translations.txtDraftInvoiceChatConversationStartInfo }} </small>
          </b-col>
        </b-row>
        <b-row class="mb-3" v-if="!disabled && messagesGrouped.length">
          <b-col cols="8" class="pe-1">
            <b>{{ translations.txtDraftInvoiceChatConversationTitle }}</b>
          </b-col>

          <b-col cols="4" class="ps-0">
            <b-button v-if="lineItem.needs_review || lineItem.needs_response" variant="link" class="p-0 float-end" @click="markDone"
              v-tooltip="translations.txtDraftInvoiceChatConversationMarkDoneInfo">
              <span> {{ translations.txtDraftInvoiceChatConversationMarkDone }} </span>
              <icon class="ms-2" name="wisk-check" :scale="0.9" />
            </b-button>
            <b-alert v-if="!lineItem.needs_response && !lineItem.needs_review" variant="success" show class="mb-0 p-0 text-center">
              <span>
                {{ translations.txtGenericResolved }}
              </span>
              <icon name="wisk-check" :scale="0.7" class="ms-2" />
            </b-alert>
          </b-col>
        </b-row>

        <div ref="scrollContainer" style="max-height: 350px; overflow-y: auto; overflow-x: hidden; padding: 10px;">
          <b-row v-for="messageGroup in messagesGrouped" :key="messageGroup.key" class="mb-3">

            <!-- Display User Name if present -->
            <b-col v-if="messageGroup?.user?.name" cols="12" class="text-muted" style="margin-bottom: -10px;" :style="{ textAlign: messageGroup.side }">
              <b style="font-size: 14px;">{{ messageGroup.user.name }}</b>
            </b-col>

            <!-- Display Time Ago -->
            <b-col cols="12" class="text-muted mt-1" :style="{ textAlign: messageGroup.side }">
              <span>{{ messageGroup.timeAgo }}</span>
            </b-col>

            <!-- Loop through messages -->
            <b-col v-for="message in messageGroup.messages" :key="message.id" :cols="12" :class="['ps-3', 'my-2']" v-tooltip="message.dateFormatted">
              <div :class="`chat-bubble-${messageGroup.side}`" >
                <p class="draft-invoice-line-message m-0">{{ message.message }}</p>
              </div>
            </b-col>

          </b-row>

          <!-- Scroll target -->
          <div class="scroll-target"></div>
        </div>

        <b-row v-if="!disabled" class="mt-2 pb-2" no-gutters>
          <b-col cols="10" class="pe-1" v-tooltip="keyboarSubmitShortcutTooltip">
            <wiskInput size="sm" infoTooltipKey="0bde0fec-587b-4130-8214-8b31fd5cc6ac" :label="messagesGrouped.length ? translations.txtDraftInvoiceChatReply : translations.txtDraftInvoiceChatMessageAdd" multiline
              v-model="newMessage" autoHeightTextArea @enter="() => { lineItem.needs_review || lineItem.needs_response ? addMessageMarkDone() : addMessage() }" autofocus/>
          </b-col>
          <b-col cols="2" :class="{ 'pt-1' : !!messagesGrouped.length }">
            <b-button v-if="!lineItem.needs_review && !lineItem.needs_response" variant="link" class="py-2 px-2 float-end w-100"
              v-tooltip="translations.txtDraftInvoiceChatSend" @click="addMessage" :disabled="!newMessage">
              <icon name="wisk-send" :scale="1.7" />
            </b-button>

            <b-dropdown v-if="lineItem.needs_review || lineItem.needs_response" :disabled="!newMessage" variant="link" size="md" toggleClass="m-0 px-1" menuClass="border-0 min-width-0">
              <template v-slot:main-btn>
                <b-button type="button" variant="link" @click="addMessageMarkDone" class="px-1" v-tooltip="translations.txtDraftInvoiceChatSendDone" :disabled="!newMessage">
                  <icon name="wisk-send-done" :scale="1.4" class="text-primary"/>
                </b-button>
              </template>
              <b-dropdown-item @click="addMessage" v-tooltip="translations.txtDraftInvoiceChatSend">
                <icon name="wisk-send" :scale="1.4" class="text-primary" />
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <wiskLoading :loading="loading" />
      </b-container>
    </template>
  </v-dropdown>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { hideAllPoppers } from 'floating-vue'
import { formatDate, guid } from '@/modules/utils'
// import testMessages from './testData'

// console.log('testMessages', testMessages)

export default {
  name: 'DraftInvoiceLineReviewMessages',
  emits: ['operations', 'click'],
  components: {},
  props: {
    disabled: Boolean,
    lineItem: Object,
    containerDomId: String,
    iconOnly: Boolean
  },
  data() {
    return {
      loading: false,
      newMessage: '',
      formatDate
    }
  },
  computed: {
    ...mapState(['translations', 'deviceInfo', 'user']),
    ...mapGetters([]),
    keyboarSubmitShortcutTooltip() {
      let shortcut = 'Ctrl + Enter'

      if (this.deviceInfo?.os?.name === 'Mac OS') {
        shortcut = 'Cmd + Enter'
      }

      return this.translations.translate('tplDraftInvoiceChatSendMessageTooltip', { '{a}': shortcut })
    },
    mainButtonTooltip() {
      if (!this.lineItem?.needs_review && !this.lineItem?.needs_response) {
        return this.translations[this.lineItem?.review_conversation?.length ? 'txtDraftInvoiceChatConversationTooltipRestart' : 'txtDraftInvoiceChatConversationTooltip']
      }

      let lastMessage = this.lineItem?.review_conversation?.length ? this.lineItem.review_conversation[this.lineItem.review_conversation.length - 1] : null

      return this.translations.translate('tplDraftInvoiceChatConversationTooltip', {
        '{a}': this.lineItem?.review_conversation?.length,
        '{b}': formatDate(lastMessage?.date),
        '{c}': '<br />' + lastMessage?.message
      })
    },
    messagesGrouped() {
      let messages = /*testMessages,//*/this.lineItem.review_conversation || [],
        result = []

      messages.sort((a, b) => new Date(a.date) - new Date(b.date))

      let currentUser = null,
        currentTimeAgo = null,
        side = null

      messages.forEach(m => {
        let message = { ...m, timeAgo: formatDate(m.date, { toRelative: true }), dateFormatted: formatDate(m.date) }

        if (!currentUser || currentUser.id !== message.user.id) {
          currentUser = { id: message.user.id, name: message.user.name }
          currentTimeAgo = message.timeAgo
          side = this.user.id === message.user.id ? 'right' : 'left'

          result.push({
            user: currentUser,
            timeAgo: currentTimeAgo,
            messages: [message],
            key: guid(),
            side
          })
        } else if (currentTimeAgo !== message.timeAgo) {
          currentTimeAgo = message.timeAgo

          result.push({
            user: null,
            timeAgo: currentTimeAgo,
            messages: [message],
            key: guid(),
            side
          })
        } else {
          result[result.length - 1].messages.push(message)
        }
      })

      return result
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    addMessage() {

      //first message come from wisk user asking the client to provide info - this can be needs_response
      //all messages from wisk will be needs_response
      //second message come from client with the info - this can be needs_review
      //all messages from client will be needs_review
      //third step is mark done which will remove needs_review and needs_response - only wisk user can do that
      //the client can't make needs_response, only the wisk user can do that
      //the wisk user can't make needs_review, only the client can do that
      //the client can't make mark done, only the wisk user can do that

      if (this.newMessage) {
        this.$emit('operations', [
          { type: 'needs_review', value: !this.user.is_wisk_user },
          { type: 'needs_response', value: !!this.user.is_wisk_user },
          { type: 'review_message', value: this.newMessage }
        ])
        this.newMessage = ''
      }
    },
    addMessageMarkDone() {
      if (this.newMessage) {
        this.$emit('operations', [{ type: 'needs_review', value: false }, { type: 'review_message', value: this.newMessage }, { type: 'needs_response', value: false }])
        this.newMessage = ''
      }
    },
    markDone() {
      this.$emit('operations', [{ type: 'needs_review', value: false }, { type: 'needs_response', value: false }])

      setTimeout(() => {
        hideAllPoppers()
      }, 0)
    },
    //called from parent
    open() {
      this.$refs.popper?.show()
    },
    scrollToBottom() {
      this.$emit('click')

      setTimeout(() => {
        let scrollContainer = this.$refs.scrollContainer || {},
          target = scrollContainer?.querySelector?.('.scroll-target')

        if (scrollContainer?.scrollHeight > scrollContainer?.clientHeight) {
          target?.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
      }, 100)
    }
  },
  watch: {
    'lineItem.review_conversation': {
      deep: true,
      handler() {
        this.scrollToBottom()
      }
    },
  }
}
</script>

<style lang="scss">
.bt-needs-attention {

  &.has-messages {
    color: var(--success);
    visibility: visible !important;
  }
  &.needs-review {
    color: var(--danger);
    visibility: visible !important;
  }
  &.needs-response {
    color: var(--danger); //let's use the same color for now
    visibility: visible !important;
  }
}

.chat-bubble-left {
  background-color: #f1f0f0;
  border-radius: 15px;
  padding: 10px;
  margin-right: auto;
  max-width: 75%;
}

.chat-bubble-right {
  background-color:#d1e7fd;
  border-radius: 15px;
  padding: 10px;
  margin-left: auto;
  max-width: 75%;
}

.draft-invoice-line-message {
  font-size: 14px;
  white-space: pre-line;
}

.min-width-0 {
  min-width: 0;
}

.dark-mode{
  .chat-bubble-left {
    background-color: #132433;
  }

  .chat-bubble-right {
    //something more blueish but darker than #d1e7fd
    background-color:  #467aaa
  }

  .draft-invoice-line-message {
    color: white;
  }
}
</style>
